import React from "react";

export const Services = (props) => {
  return (
    <div id="our-services" className="container">
      <div className="our-services-block">
        <div className="title1">Development Services</div>
        <div className="line" />
        <div className="our-services">
          <div className="row">
            <div className="col-md-4 our-service">
              <i className="icon icon-our-serviced"></i>
              <div className="our-service-title">
                Enterprise <br /> Web Solutions
              </div>
              <div className="our-service-description">
                Whether you work in the office, at home or on the way to the
                airport – run your business online. We automate complex systems,
                making them simple to use and maintain no matter where you are.
              </div>
              <div className="line-border" />
            </div>

            <div className="col-md-4 our-service">
              <i className="icon icon-our-servicee"></i>
              <div className="our-service-title">
                Cloud Applications <br /> (SAAS)
              </div>
              <div className="our-service-description">
                We know you rely on the security, high performance and
                cost-effectiveness of doing things online. That’s why all the
                software we build runs on any of your preferred cloud systems.
              </div>
              <div className="line-border" />
            </div>

            <div className="col-md-4 our-service">
              <i className="icon icon-our-servicef"></i>
              <div className="our-service-title">
                Multiplatform <br /> Application Development
              </div>
              <div className="our-service-description">
                Build once, run anywhere. Create responsive applications that
                adapt to your customers’ habits. Engage with them on their
                phones, tablets or PC whether they are at work, on the bus or
                resting on the sofa.
              </div>
              <div className="line-border" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
