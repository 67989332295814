import React from "react";
import MasterOfAgile from "../images/masters-of-agile.svg";
export const AgileDevelopment = () => {
  return (
    <div className="moa-container">
      <div className="moa-head">
        <div>
          <h2 className="moa-heading">Leaders in</h2>
          <h2 className="moa-heading">Agile Development</h2>
        </div>

        <span className="moa-text" style={{ maxWidth: 450 }}>
          We are good at what we do. In fact, all our customers will attest to
          the fact that they were surprised at our methods and throughput. So
          completing your project won’t be a unique achievement. Instead you
          will be simply joining our long list of happy customers.
        </span>
      </div>
      <img src={MasterOfAgile} alt="Responsive Design" className="img-moa" />
    </div>
  );
};
