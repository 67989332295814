import React from "react";
import partnersImage from "../images/partners.webp";
export const Contact = () => {
  return (
    <div
      style={{
        minHeight: 250,
        background: "#2a393e",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexWrap: "wrap",
        padding: 20,
        gap: 50,
      }}
    >
      <div>
        <h1 style={{ color: "white" }}>HCR International</h1>
        <h6>Copyright © 2022-2023 HCR International</h6>
      </div>

      <div>
        <h5 style={{ color: "white", marginBottom: 20 }}>Contact</h5>
        <h6>Address</h6>
        <span style={{ color: "white", display: "block" }}>
          Premises Number B223-E,
        </span>
        <span style={{ color: "white", display: "block" }}>Techno Hub 2,</span>
        <span style={{ color: "white", display: "block" }}>Dubai,</span>
        <span style={{ color: "white", display: "block" }}>
          United Arab Emirates
        </span>

        <h6>Email</h6>

        <a href="mailto:hello@hcr-int.com" style={{ color: "white" }}>
          hello@hcr-int.com
        </a>
      </div>

      <div>
        <h5 style={{ color: "white", marginBottom: 20 }}>Partner</h5>
        <img src={partnersImage} alt="PartnersImage" />
      </div>
    </div>
  );
};
