import React, { useRef, useEffect, useState } from "react";

export const Navigation = (props) => {
  const [isNavScrolled, setIsNavScrolled] = useState(false);
  const navRef = useRef();
  navRef.current = isNavScrolled;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setIsNavScrolled(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id="menu"
      className="navbar-animated navbar-default navbar-fixed-top"
      style={{
        transition: "0.5s ease",
        backgroundColor: isNavScrolled ? "rgba(0,0,0,.6)" : "transparent",
        padding: isNavScrolled ? "5px 5px 5px 5px" : "20px 5px 5px 5px",
        marginRight: isNavScrolled ? -10 : 0,
      }}
    >
      <div className="container">
        <div className="navbar-header">
          <a
            className="navbar-brand page-scroll"
            href="#page-top"
            style={{
              transition: "0.5s ease",
              color: "white",
              marginLeft: isNavScrolled ? 10 : 0,
            }}
          >
            HCR International
          </a>
        </div>
      </div>
    </nav>
  );
};
