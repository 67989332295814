import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12  intro-text ">
                <h1>
                  You’ll Think You Made It Yourself:
                  <span></span>
                </h1>
                <p>Our Software Does What You Want It to Do.</p>
                <a
                  href="mailto:hello@hcr-int.com"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Quick Start
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
