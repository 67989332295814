import React from "react";
import VerticalsImage from "../images/verticals.png";

export const Verticals = () => {
  return (
    <div className="vertical-container">
      <div className="vertical-head">
        <div>
          <h2 className="vertical-heading">Verticals</h2>
        </div>

        <span className="vertical-text" style={{ maxWidth: 450 }}>
          Working with HCR International ensures that your solution is adapted
          to your industry, thanks to our expertise working for leading
          companies in the following verticals:
        </span>
      </div>
      <img
        src={VerticalsImage}
        alt="Responsive Design"
        className="img-vertical"
      />
    </div>
  );
};
