import React from "react";
import TechnologiesImage from "../images/technologies.webp";
export const FullStackDeveloper = () => {
  return (
    <div className="fsd-container">
      <div className="fsd-left-div">
        <h2
          className="fsd-text"
          style={{ fontWeight: 500, lineHeight: 1.2, fontSize: 46 }}
        >
          Full-Stack Developers at your Disposal
        </h2>

        <span
          className="fsd-text"
          style={{ marginTop: 10, fontSize: 18, fontWeight: 450 }}
        >
          Work with our skillful full-stack web developers. We manage web
          development projects with the latest technologies, ensuring that you
          deliver a unique experience to your users. HCR International ́s
          full-stack web developers specialise in the following technologies:
        </span>
      </div>
      <div>
        <img
          src={TechnologiesImage}
          alt="Responsive Design"
          className="img-fsd"
        />
      </div>
    </div>
  );
};
