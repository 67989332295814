import React from "react";
import ResponsiveDesignImage from "../images/responsive-design.webp";
export const ResponsiveDesign = () => {
  return (
    <div className="responsive-container">
      <div className="responsive-left-div">
        <h2 className="responsive-text">Responsive Design</h2>
        <h3
          className="responsive-text"
          style={{ marginTop: 10, fontSize: 24, fontWeight: 600 }}
        >
          Build a customer friendly solution that is easy to use on any
          platform.
        </h3>
        <span
          className="responsive-text"
          style={{ marginTop: 40, fontSize: 18, fontWeight: 450 }}
        >
          Your customers are not all the same. Some hang out on their phones,
          others still prefer using their laptop.
        </span>
        <span
          className="responsive-text"
          style={{ marginTop: 10, fontSize: 18, fontWeight: 450 }}
        >
          That’s why we provide a cross-device solution that gives your users a
          consistent experience across all platforms.
        </span>
      </div>
      <div>
        <img
          src={ResponsiveDesignImage}
          alt="Responsive Design"
          className="img-responsive"
        />
      </div>
    </div>
  );
};
