import React, { useState, useEffect } from "react";

const feedback = [
  {
    message:
      "Improved performance, resilience and reduced costs... What a way to start, a what is now, a long standing relationship!",
    name: "Martin Fooks",
    position: "Head of Archecture- Medtronic",
  },
  {
    message:
      "We tried several companies when we started this project and HCR International came out way ahead of the others in terms of quality and speed. We are extremely happy.",
    name: "Isa Truchet",
    position: "Programme Director - SDAIA",
  },
  {
    message:
      "HCR International is the best software company we have dealt with so far. Speed is great on the system delivered. A nice product to use, stable and scalable",
    name: "Aamar Maqbool",
    position: "Digital Transformation Leader - DAZN",
  },
];

export const ClientFeedback = () => {
  const [selectFeedBackIndex, setSelectFeedBackIndex] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setSelectFeedBackIndex((index) => {
        if (index === feedback.length - 1) {
          return 0;
        }
        return index + 1;
      });
    }, 5000);
  }, []);
  return (
    <div className="client-feedback-container">
      <div className="client-feedback-left-div">
        <h2
          className="client-feedback-text"
          style={{
            fontWeight: 500,
            lineHeight: 1.2,
            fontSize: 46,
            color: "white",
            width: 300,
          }}
        >
          Hear it from our clients
        </h2>
        <div
          style={{ display: "flex", gap: 10, marginTop: 50, marginLeft: 10 }}
        >
          {feedback.map((fb, index) => {
            return (
              <div
                onClick={() => {
                  setSelectFeedBackIndex(index);
                }}
                style={{
                  width: 10,
                  height: 10,
                  background:
                    index === selectFeedBackIndex ? "white" : "#a9afb1",
                  borderRadius: 10,
                  cursor: "pointer",
                }}
              />
            );
          })}
        </div>
      </div>

      <div style={{ width: 600, minHeight: 400 }}>
        <span style={{ color: "white", fontWeight: 400, fontSize: "3rem" }}>
          “{feedback[selectFeedBackIndex].message}”
        </span>
        <span
          style={{
            marginTop: 50,
            color: "white",
            fontWeight: 400,
            fontSize: "2rem",
            display: "block",
          }}
        >
          {feedback[selectFeedBackIndex].name}
        </span>
        <span
          style={{
            color: "#a9afb1",
            fontWeight: 400,
            fontSize: "2rem",
            display: "block",
          }}
        >
          {feedback[selectFeedBackIndex].position}
        </span>
      </div>
    </div>
  );
};
